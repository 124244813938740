<template>
  <div>
    <b-card
      no-body
      class="saj-title mr-1"
      style=""
    >
      <div
        class="saj-title pl-2 pt-1"
        style=""
      >
        {{ $t('Project Name') }} : {{ projectTitle }}
      </div>

      <div
        class="saj-title pl-2"
        style=""
      >
        {{ $t('Project Creator') }} : {{ assignee }}
      </div>

      <!-- </b-card-header> -->
      <b-card-body>
        <b-button
          v-b-modal.add-member
          class="btn saj-button mb-1"
          variant="info"
        >
          {{ $t('Add Project Member') }}
        </b-button>
        <b-table
          show-empty
          :items="memberList"
          :fields="fields"
          responsive=""
          bordered
          class="mt-1 mb-1 saj-subtitle"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>

          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>

          <template #cell(employee_number)="data">
            {{ data.item.employee_number }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(activity)="data">
            {{ $t(data.item.activity) }}
          </template>

          <template #cell(progress)="data">
            <div class="d-flex justify-content-center">
              {{ $t(data.item.progress) + "%" }}
            </div>
          </template>

          <template #cell(remarks)="data">
            <div class="d-flex justify-content-center">
              {{ $t(data.item.remarks === null ? '-' : data.item.remarks) }}
            </div>
            <div>
              <p
                v-b-modal.view-history
                class="mt-1 d-flex justify-content-center"
                style="text-decoration: underline; color: #5555ff;"
                @click="memberId = data.item.id"
              >
                {{ $t("View Timeline") }}
              </p>
            </div>
          </template>

          <template #cell(assigned_date)="data">
            <div class="d-flex justify-content-center">
              {{ data.item.assigned_date !== null ? DateChanged(data.item.assigned_date) : '-' }}
            </div>
          </template>

          <template #cell(due_date)="data">
            <div
              class="d-flex justify-content-center"
            >
              {{ data.item.due_date !== null ? DateChanged(data.item.due_date) : '-' }}
            </div>
          </template>

          <template #cell(action)="data">
            <div
              class="d-flex justify-content-center"
            >
              <feather-icon
                v-b-modal.edit-member
                v-b-tooltip.hover.top="$t('Edit')"
                icon="EditIcon"
                size="25"
                style="color: green"
                class="mr-1"
                @click="memberData = data"
              />
              <feather-icon
                v-b-modal.delete-member
                v-b-tooltip.hover.top="$t('Delete')"
                icon="Trash2Icon"
                size="25"
                style="color: red"
                @click="memberData = data"
              />
            </div> </template></b-table>
        <b-modal
          id="add-member"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <add-member
            :project-i-d="id"
            @close="$bvModal.hide('add-member')"
            @add-new-member="viewProjectMember()"
          />
        </b-modal>
        <b-modal
          id="edit-member"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <EditMember
            :project-i-d="id"
            :member-info="memberData"
            @close="$bvModal.hide('edit-member')"
            @update-activity="viewProjectMember()"
          />
        </b-modal>
        <b-modal
          id="delete-member"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <delete-member
            :project-i-d="id"
            :member-info="memberData"
            @close="$bvModal.hide('delete-member')"
            @delete-member="viewProjectMember()"
          />
        </b-modal>
        <b-modal
          id="view-history"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="xl"
        >
          <viewHistory
            :project-member-id="memberId"
            @close="$bvModal.hide('view-history')"
          />
        </b-modal>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  // BCardHeader,
  BCardBody,
  BTable,
  VBTooltip,
  BButton,
} from "bootstrap-vue"
// import { mapGetters } from 'vuex'
import moment from "moment"
import addMember from "@/component/projectManagement/addMember.vue"
import EditMember from "@/component/projectManagement/EditMember.vue"
import DeleteMember from "@/component/projectManagement/deleteMember.vue"
import viewHistory from "@/component/projectManagement/view-history.vue"

export default {
  components: {
    BCard,
    // BCardHeader,
    BCardBody,
    BTable,
    BButton,
    addMember,
    EditMember,
    DeleteMember,
    viewHistory,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      memberList: [],
      memberData: null,
      fields: [
        {
          key: 'employee_number', label: 'Employee No.', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'name', label: 'Name', thClass: 'text-center' },
        { key: 'activity', label: 'Activity', thClass: 'text-center' },
        { key: 'progress', label: 'Status', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
        {
          key: 'assigned_date', label: 'Assigned Date', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'due_date', label: 'Due Date', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      assignee: null,
      memberId: null,
    }
  },
  computed: {
  },
  mounted() {
    this.viewProjectMember()
  },
  beforeMount() {
    this.id = this.$route.params.id
    this.projectTitle = this.$route.params.projectTitle
  },
  methods: {
    viewProjectMember() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/executive/getByID?pm_id=${this.id}`, data).then(response => {
        // console.log('memberlist', response)
        if (response.data.data.length !== 0){
          this.memberList = response.data.data.pm_setting
          this.assignee = response.data.data.assignee
          // console.log('project view member', response.data.data)
        }
      })
    },
    DateChanged(date) {
      const newDay = moment(date).format("D-MM-yyyy")
      return newDay
    },
  },

}
</script>
