<template>
  <div class="p-1" style="width: auto">
    <div class="mb-1 d-flex justify-content-center saj-header">
      <p>{{ $t("Edit Member") }}</p>
      <!-- {{ projectID }} -->
      <!-- {{ memberInfo }} -->
    </div>
    <h4 class="saj-title">{{ $t("Name") }}: {{ memberInfo.item.name }}</h4>
    <validation-observer ref="simpleRules">
      <b-form>
        <!-- dropdown name -->
        <!-- <b-form-group class="eventtext pt-1" label="Name">
              <v-select
                v-model="selected"
                class="eventtext select-size-lg"
                style="background: white"
                label="text"
                :options="nameList"
                :value="nameList"
                :close-on-select="true"
              />
            </b-form-group> -->
        <!-- dropdown name -->

        <b-form-group class="saj-title" :label="$t('Activity / Position')">
          <validation-provider
            #default="{ errors }"
            :name="$t('Activity / Position')"
            rules="required"
          >
            <b-form-textarea v-model="activity" class="saj-subtitle" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="saj-title" :label="$t('Progress (%)')">
          <validation-provider
            #default="{ errors }"
            :name="$t('Progress (%)')"
            :rules="{
              required,
              max_value: 100,
              min_value: 1,
            }"
          >
            <b-form-input
              v-model="progress"
              class="saj-subtitle"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="saj-title" :label="$t('Progress Remarks')">
          <b-form-input v-model="remarks" class="saj-subtitle" />
        </b-form-group>

        <b-form-group class="saj-title" :label="$t('Assigned Date')">
          <div class="" style="background-color: #fff">
            <!-- assign {{ assign }} -->
            <flat-pickr
              v-model="assign"
              :placeholder="$t('Choose date')"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
              }"
            />
          </div>
        </b-form-group>

        <b-form-group class="saj-title" :label="$t('Due Date')">
          <div class="" style="background-color: #fff">
            <!-- due {{ due }} -->
            <flat-pickr
              v-model="due"
              :placeholder="$t('Choose date')"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
              }"
            />
          </div>
        </b-form-group>
      </b-form>
    </validation-observer>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="color: white; background: #ff0000"
        @click="close()"
      >
        {{ $t("Back") }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="
          color: white;
          background: #0b103c !important;
          border-color: #0b103c !important;
        "
        @click="EditMember()"
      >
        {{ $t("Save") }}
      </button>
    </div>
  </div>
</template>
<script>
import { BForm, BFormInput, BFormGroup, BFormTextarea } from "bootstrap-vue";
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
// import { required, max_value } from '@validations'
import { required, max_value, min_value } from "vee-validate/dist/rules";
import SAJToast from "@/component/saj-toastification.vue";
import flatPickr from "vue-flatpickr-component";

extend("max_value", max_value);
extend("min_value", min_value);

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  props: {
    projectID: {
      type: Number,
      default: null,
    },
    memberInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      allName: [],
      nameList: [],
      pmID: null,
      activity: "",
      progress: "",
      remarks: "",
      selected: "",
      member: "",
      required,
      assign: null,
      due: null,
    };
  },
  mounted() {
    // console.log('kentang', this.id)
    // console.log('member', this.member)
    this.pmID = this.id;
    // console.log(this.memberInfo)
    this.member = this.memberInfo.item.employee_id;
    this.activity = this.memberInfo.item.activity;
    this.progress = this.memberInfo.item.progress;
    this.remarks = this.memberInfo.item.remarks;
    this.assign = this.memberInfo.item.assigned_date;
    this.due = this.memberInfo.item.due_date;
    // this.getMemberName()
  },
  methods: {
    close() {
      this.$emit("close");
    },
    EditMember() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const data = new FormData();
          data.append("pm_id", this.projectID);
          // data.append('employee_id', this.selected.value)
          data.append("employee_id", this.member);
          data.append("activity", this.activity);
          data.append("progress", this.progress);
          data.append("remarks", this.remarks);
          if (this.assign !== null) {
            data.append(`assigned_date`, this.assign);
          }
          if (this.due !== null) {
            data.append(`due_date`, this.due);
          }

          // data.forEach(x => {
          //   console.log('edit member', x)
          // })

          this.$axios
            .post(`${this.$baseUrl}/executive/updateUserProject`, data)
            .then(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t("Successfully save changes")}.`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: "info",
                }
              );
              this.$emit("close");
              this.$emit("update-activity");
            })
            .catch((err) => {
              if (err) {
                const message = err.response.data.message;
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t(
                        message
                      )}.`,
                      icon: "XIcon",
                      iconBg: "#e80202",
                      variant: "danger",
                      titleColor: "#000",
                    },
                  },
                  {
                    position: "top-right",
                    type: "error",
                  }
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
[dir] .b-form-btn-label-control.form-control > .dropdown-menu {
  padding-bottom: 3rem;
}
</style>
