<template>
  <div class="p-1">
    <div class="d-flex justify-content-center">
      <p style="font-size: 1.7rem;">
        {{ $t("View Timeline") }}
      </p>
    </div>
    <div
      class="d-flex  align-items-center"
    >
      <b-table
        show-empty
        :items="historyList"
        :fields="fields"
        responsive=""
        bordered
        class="mt-1 mb-1 saj-subtitle"
      >
        <template #head()="data">
          <span
            class="saj-title"
            style=""
          >{{ $t(data.label) }}</span>
        </template>

        <template #empty="">
          <h4 style="text-align: center; font-style: italic">
            {{ $t('There are no records to show') }}
          </h4>
        </template>

        <template #cell(activity)="data">
          {{ $t(data.item.activity) }}
        </template>

        <template #cell(progress)="data">
          <div class="d-flex justify-content-center">
            {{ $t(data.item.progress) + "%" }}
          </div>
        </template>

        <template #cell(remarks)="data">
          <div class="d-flex justify-content-center">
            {{ $t(data.item.remarks === null ? '-' : data.item.remarks) }}
          </div>
        </template>

        <template #cell(date)="data">
          <div
            class="d-flex justify-content-center"
          >
            {{ DateChanged(data.item.created_at) }}
          </div>
        </template>

      </b-table>

    </div>
  </div>
</template>
<script>
import {
  BTable,
} from "bootstrap-vue"
import moment from "moment"

export default {
  components: {
    BTable,

  },
  props: {
    projectMemberId: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      historyList: null,
      fields: [
        { key: 'activity', label: 'Activity', thClass: 'text-center' },
        { key: 'progress', label: 'Status', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
        {
          key: 'date', label: 'Date', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.viewHistory()
  },
  methods: {
    viewHistory(){
      this.$axios.get(`${this.$baseUrl}/getProjectManagementHistory?id=${this.projectMemberId}`).then(response => {
        this.historyList = response.data.data
        // console.log(this.historyList)
      })
    },
    DateChanged(date) {
      const newDay = moment(date).format("D-MM-yyyy")
      return newDay
    },
  },
}
</script>
