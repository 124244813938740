<template>
  <div
    class="p-1 modalScroll"
    style="width: auto; height: 75vh;"
  >
    <div
      class="mb-1 d-flex justify-content-center saj-header"
    >
      {{ $t('Add Project Member') }}
      <!-- {{ projectID }} -->
    </div>

    <validation-observer ref="simpleRules">

      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain, removeLast"
      >
        <b-row
          v-for="item in items"
          :id="item.id"
          :key="item.id"
          ref="row">
          <!-- dropdown name -->
          <b-col
            cols=""
            class="m-1 p-1"
            style="background: #f7f7fc"
          >
            <b-form-group
              class="saj-title pt-1"
              :label="$t('Subsidiary')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Subsidiary')"
                rules="required"
              >
                <v-select
                  v-model="item.subsideries"
                  class="saj-title select-size-lg"
                  style="background: white"
                  label="text"
                  :placeholder="$t('Choose Subsidiary')"
                  :options="subList"
                  :value="subList"
                  :close-on-select="true"
                  @input="getMemberName(item.subsideries)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              class="saj-title mt-1"
              :label="$t('Choose Employee Number / Name')"
            >
              <!-- Employee ID or Employee Name Button -->
              <b-button
                v-model="buttonEmployee"
                variant="primary"
                class="mt-1"
                style=""
                @click="isEmployeeID = !isEmployeeID, isEmployeeName = !isEmployeeName"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ isEmployeeID === true ? $t("Employee Number") : $t("Employee Name") }} </span>
              </b-button>
              <!-- Employee ID or Employee Name Button End -->
              <div
                v-if="!isEmployeeName"
              >
                <!-- ID -->
                <b-form-group
                  class="saj-title mt-1"
                  :label="$t('Employee Number')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Employee Number')"
                    rules="required"
                  >
                    <v-select
                      v-model="item.selected"
                      class="saj-title select-size-lg"
                      :selectable="(option) => !allSelectedName.includes(option.value)"
                      style="background: white"
                      label="empNo"
                      :placeholder="$t('Choose Number')"
                      :disabled="item.subsideries === null && !isLoading || item.subsideries === ''"
                      :options="nameList"
                      :value="nameList"
                      :close-on-select="true"
                      :loading="isLoading"
                      @input="selectedNumber()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-form-input
                    v-model="item.selected.text"
                    :placeholder="$t('Employee Name')"
                    class="saj-text mt-1"
                    disabled
                  />
                </b-form-group>
              </div>

              <div v-if="isEmployeeName">
                <!-- Name -->
                <b-form-group
                  class="saj-title mt-1"
                  :label="$t('Employee Name')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Employee Name')"
                    rules="required"
                  >
                    <v-select
                      v-model="item.selected"
                      class="saj-title select-size-lg"
                      :selectable="(option) => !allSelectedName.includes(option.value)"
                      style="background: white"
                      label="text"
                      :placeholder="$t('Choose Name')"
                      :disabled="item.subsideries === null && !isLoading || item.subsideries === ''"
                      :options="nameList"
                      :value="nameList"
                      :close-on-select="true"
                      :loading="isLoading"
                      @input="selectedNumber()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-form-input
                    v-model="item.selected.empNo"
                    :placeholder="$t('Employee Number')"
                    class="saj-text mt-1"
                    disabled
                  />
                </b-form-group>

              </div>
            </b-form-group>
            <!-- dropdown name -->

            <b-form-group
              class="saj-title"
              :label="$t('Activity / Position')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Activity / Position')"
                rules="required"
              >
                <b-form-textarea
                  v-model="item.activity"
                  :disabled="item.selected === null || item.selected === ''"
                  class="saj-title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              class="saj-title"
              :label="$t('Progress (%)')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Progress (%)')"
                :rules="{
                  required,
                  max_value:100,
                  min_value:1,
                }"
              >
                <b-form-input
                  v-model="item.progress"
                  :placeholder="$t('Number only')"
                  type="number"
                  class="saj-title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              class="saj-title"
              :label="$t('Progress Remarks')"
            >
              <b-form-input
                v-model="item.remarks"
                class="saj-subtitle"
              />
            </b-form-group>

            <b-form-group
              class="saj-title"
              :label="$t('Assigned Date')"
            >
              <div
                class=""
                style="background-color: #fff;"
              >
                <flat-pickr
                  v-model="item.assign"
                  :placeholder="$t('Choose date')"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                />
              </div>
            </b-form-group>

            <b-form-group
              class="saj-title"
              :label="$t('Due Date')"
            >
              <div
                class=""
                style="background-color: #fff;"
              >
                <flat-pickr
                  v-model="item.due"
                  :placeholder="$t('Choose date')"
                  :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
                />
              </div>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row
          cols=""
          class="d-flex justify-content-center mb-1 mt-3"
          style="
              <!-- background:lightblue -->
              "
        >
          <feather-icon
            class="pointer"
            style="
                  width: 30px;
                  height: 30px;
                  color: #c5c7cd;
                  margin-right: 20px;
                "
            icon="PlusCircleIcon"
            @click="repeateAgain"
          />

          <feather-icon
            v-if="items.length > 1"
            class="pointer"
            style="
                  width: 30px;
                  height: 30px;
                  color: #c5c7cd;
                  margin-right: 20px;
                "
            icon="MinusCircleIcon"
            @click="removeLast"
          />
        </b-row>
      </b-form>
    </validation-observer>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-subtitle"
        style="color: white;
                background: #ff0000;
              "
        @click="close()"
      >
        {{ $t('Back') }}
      </button>
      <button
        class="btn m-1 saj-subtitle"
        style="
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
        @click="AddMember()"
      >
        {{ $t('Submit') }}
      </button>
    </div>
  </div>

</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// import { required, max_value } from '@validations'
import {
  required,
  max_value,
  min_value,
} from 'vee-validate/dist/rules'

import {
  BButton,
  //   BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  //   BCard,
  //   BFormDatepicker,
  BRow,
  BCol,
  //   BCalendar,
  //   BCardText,
  // BFormSelect,
} from "bootstrap-vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import { heightTransition } from "@core/mixins/ui/transition"
import SAJToast from '@/component/saj-toastification.vue'
import flatPickr from "vue-flatpickr-component"

extend('max_value', max_value)
extend('min_value', min_value)

export default {
  components: {
    BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BCard,
    // BFormDatepicker,
    // BCardCode,
    vSelect,
    BRow,
    BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
    // BFormSelect,
    flatPickr,
  },
  mixins: [heightTransition],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    projectID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      existingIds: [],
      projMember: [],
      allSelectedName: [],
      subsidiaryID: "",
      // pmID: null,
      dispName: [],
      allSubsideriesName: [],
      selected: "",
      nameList: [],
      subList: [],
      items: [{
        selected: [],
        subsideries: [],
        activity: "",
        progress: "",
        assign: null,
        due: null,
      }],
      nextTodoId: 2,
      required,
      currentPage: 1,
      lastPage: 0,
      rows: 0,
      isLoading: false,
      isEmployeeID: false,
      isEmployeeName: false,
      buttonEmployee: null,
      numberList: [],
    }
  },

  watch: {

  },
  mounted() {
    this.getSubsidiary()
    // this.pmID = this.id
    this.getProjMember()
  },
  methods: {
    // clear(){
    //   this.item.selected = ""
    //   this.item.selectedName = ""
    // },
    AddMember() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          for (let i = 0; i < this.items.length; i += 1) {
            if (this.items[i].activity !== "") {
              data.append('pm_id', this.projectID)
              // this.selected.forEach(emp => {
              //   data.append("employee_id[]", emp)
              // })
              data.append(`employee_id[]`, this.items[i].selected.value)
              data.append(`activity[]`, this.items[i].activity)
              data.append(`progress[]`, this.items[i].progress)
              data.append('remarks[]', this.items[i].remarks === undefined ? '-' : this.items[i].remarks)
              if (this.items[i].assign !== null){
                data.append(`assigned_date[]`, this.items[i].assign)
              }
              if (this.items[i].due !== null){
                data.append(`due_date[]`, this.items[i].due)
              }
            }
          }

          // data.forEach(x => {
          //   console.log('add member', x)
          // })

          this.$axios.post(`${this.$baseUrl}/executive/addMember`, data).then(() => {
            setTimeout(() => {
              this.$emit('close')
              this.$emit('add-new-member')
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Successfully added to the project')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 1000)
          })
          .catch((err) => {
              if (err) {
                const message = err.response.data.message;
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t(
                        message
                      )}.`,
                      icon: "XIcon",
                      iconBg: "#e80202",
                      variant: "danger",
                      titleColor: "#000",
                    },
                  },
                  {
                    position: "top-right",
                    type: "error",
                  }
                );
              }
            });
        }
      })
    },

    getSubsidiary() {
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allSubsideriesName = response.data.data.subsidiaries
        // const prevData = _.cloneDeep(response.data.data.subsidiaries)
        // console.log("ALL SUBSIDERIES SINI!", this.allSubsideriesName[0].id);
        // this.subsidiaryID = this.allSubsideriesName[0].id
        // console.log("INI ID!", this.subsidiaryID);
        this.allSubsideriesName.forEach(nameSub => {
          this.subList.push({
            value: nameSub.id,
            text: nameSub.subsidiary_name,
          })
        })
      })
    },
    // getProjMember() {
    //   const data = new FormData()
    //   this.$axios.get(`${this.$baseUrl}/executive/getProjectManagement`, data).then(response => {
    //     if (response.data.data.length !== 0){
    //       if (response.data.data[0].pm_setting !== null){
    //         this.projMember = response.data.data[0].pm_setting
    //         const allFilter = this.projMember.map(user => Number(user.employee_id))
    //         this.existingIds = allFilter
    //         this.allSelectedName = this.existingIds
    //       }
    //     }
    //   })
    // },
    getProjMember() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/executive/getByID?pm_id=${this.projectID}`, data).then(response => {
        // console.log('memberlist', response)
        if (response.data.data.length !== 0){
          this.projMember = response.data.data.pm_setting

          const allFilter = this.projMember.map(user => Number(user.employee_id))
          this.existingIds = allFilter
          this.allSelectedName = this.existingIds
        }
      })
    },

    selectedNumber(){
      // console.log('items', this.items)
      this.allSelectedName = [...this.items.map(x => {
        if (x.selected === null){
          // eslint-disable-next-line no-param-reassign
          x.selected = [{ text: "", value: null }]
          return x.selected
        }

        return x.selected.value
      }), ...this.existingIds]
    },

    getMemberName(data) {
      // console.log('masuk member name', id)
      // const params = new FormData()
      // params.append("subsidiary_id", this.id)
      this.nameList = []
      this.isLoading = true
      const params = new URLSearchParams()

      params.append('subsidiary_id', data.value)

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?status=active`, { params }).then(response => {
        const allName = response.data.data

        this.nameList = allName.map(item => ({
          value: item.employee_id,
          empNo: item.employee_number,
          text: item.full_name,
        }))
        this.isLoading = false
      })
    },

    close() {
      this.$emit('close')
    },
    repeateAgain() {
      this.items.push({
        selected: [],
        subsideries: [],
        activity: "",
        progress: "",
        assign: null,
        due: null,
      })
    },
    removeLast(){
      const tempItems = this.items.pop()

      this.allSelectedName = this.allSelectedName.filter(x => x !== tempItems.selected.value)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.hiddenFileInput > input {
  height: 100%;
  width: 90;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInput {
  /*display: flex;
  justify-content: center;
  padding-bottom: 30px; */
  display: inline-block;
  overflow: hidden;
  height: 60px;
  width: 150px;
  background: no-repeat;
  background-image: url("~@/assets/images/button/muatnaik button.png");
}
</style>
