var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1",staticStyle:{"width":"auto"}},[_c('div',{staticClass:"mb-1 d-flex justify-content-center saj-header"},[_c('p',[_vm._v(_vm._s(_vm.$t("Edit Member")))])]),_c('h4',{staticClass:"saj-title"},[_vm._v(_vm._s(_vm.$t("Name"))+": "+_vm._s(_vm.memberInfo.item.name))]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-form-group',{staticClass:"saj-title",attrs:{"label":_vm.$t('Activity / Position')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Activity / Position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"saj-subtitle",model:{value:(_vm.activity),callback:function ($$v) {_vm.activity=$$v},expression:"activity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"saj-title",attrs:{"label":_vm.$t('Progress (%)')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Progress (%)'),"rules":{
            required: _vm.required,
            max_value: 100,
            min_value: 1,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"saj-subtitle",attrs:{"type":"number"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"saj-title",attrs:{"label":_vm.$t('Progress Remarks')}},[_c('b-form-input',{staticClass:"saj-subtitle",model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1),_c('b-form-group',{staticClass:"saj-title",attrs:{"label":_vm.$t('Assigned Date')}},[_c('div',{staticStyle:{"background-color":"#fff"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.$t('Choose date'),"config":{
              dateFormat: 'Y-m-d',
              altInput: true,
              altFormat: 'd-m-Y',
            }},model:{value:(_vm.assign),callback:function ($$v) {_vm.assign=$$v},expression:"assign"}})],1)]),_c('b-form-group',{staticClass:"saj-title",attrs:{"label":_vm.$t('Due Date')}},[_c('div',{staticStyle:{"background-color":"#fff"}},[_c('flat-pickr',{attrs:{"placeholder":_vm.$t('Choose date'),"config":{
              dateFormat: 'Y-m-d',
              altInput: true,
              altFormat: 'd-m-Y',
            }},model:{value:(_vm.due),callback:function ($$v) {_vm.due=$$v},expression:"due"}})],1)])],1)],1),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"<!-- background":"pink"}},[_c('button',{staticClass:"btn btn-close m-1 saj-button",staticStyle:{"color":"white","background":"#ff0000"},attrs:{"aria-label":"Close modal"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('button',{staticClass:"btn m-1 saj-button",staticStyle:{"color":"white","background":"#0b103c !important","border-color":"#0b103c !important"},attrs:{"aria-label":"submit modal"},on:{"click":function($event){return _vm.EditMember()}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }