<template>
  <div
    class="p-1"
    style="width: auto"
  >
    <p class="m-1 p-1 saj-header">
      {{ $t("Are you sure you want to delete") + '?' }}

      <!-- {{ member }} {{ id }} -->
    </p>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="
                color: white;
                background: #ff0000;
              "
        @click="close()"
      >
        {{ $t("Cancel") }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
        @click="deleteProject()"
      >
        {{ $t("Confirm") }}

        <!-- {{ projectID }} -->
        <!-- {{ member }} -->
      </button>
    </div>
  </div>

</template>
<script>
import {

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    // BButton,
    // BModal,
    // BForm,
    // BFormInput,
    // BFormGroup,
    // BFormTextarea,
    // BCard,
    // BFormDatepicker,
    // BCardCode,
    // vSelect,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
  },
  props: {
    projectID: {
      type: Number,
      default: null,
    },
    memberInfo: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      member: '',
    }
  },
  mounted() {
    // console.log('NI PROJECT ID', this.id)
    // console.log('member', this.memberInfo.item.employee_id)
    this.member = this.memberInfo.item.employee_id
  },
  methods: {
    close() {
      this.$emit('close')
    },
    deleteProject() {
      this.$axios.post(`${this.$baseUrl}/executive/deleteUser?pm_id=${this.projectID}&employee_id=${this.member}`).then(() => {
        // this.$emit('close')
        setTimeout(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully deleted')}.`,
                icon: "Trash2Icon",
                iconBg: "#e80202",
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.$emit('close')
          this.$emit('delete-member')
        }, 1000)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
</style>
